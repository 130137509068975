@import url("https://fonts.googleapis.com/css?family=Spartan&display=swap");
html{
  height: 100%;
  background-color: #53c2be;
}
body {
  height: 100%;
  margin: 0;
  font-family: "Open Sans", "Source Sans Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Open Sans", sans-serif;
}

#root {
  height: 100%;
}
